body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "Roboto";
	src: url("/public/fonts/Roboto-Bold.woff2") format("woff2"),
		url("/public/fonts/Roboto-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Roboto";
	src: url("/public/fonts/Roboto-Regular.woff2") format("woff2"),
		url("/public/fonts/Roboto-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Indigo-Regular";
	src: url("/public/fonts/Indigo-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Indigo-Outline";
	src: url("/public/fonts/Indigo-Outline.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: "Roboto", serif;
}

p {
	margin-top: 0;
	margin-bottom: 8px;
}

.page {
	overflow-x: hidden;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	height: 100vh;
	width: 100%;
	width: 100vw;
	transform-style: preserve-3d;
	transform: perspective(1000px);
}

#canvas-basic {
	background-color: #560094;
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.logo {
	width: 100%;
	padding: 0 20%;
	transform: translateZ(20px);
}
@media all and (max-width: 768px) {
	.logo {
		padding: 0 20px;
	}
}

.actionpanel {
	position: absolute;
	left: 32px;
	top: 32px;
	background-color: #560094;
	border-radius: 50%;
	height: 200px;
	width: 200px;
	text-align: center;
	vertical-align: center;
	display: flex;
	align-items: center;
	border: 8px solid #9b1763;
	justify-content: center;
	cursor: pointer;
	line-height: 40px;
	z-index: 2;
	transition: all 1s ease;
}

.actionpanel.full {
	width: 100%;
	height: 100%;
}

.contact__content {
	display: none;
}

.actiopanel .actionpanel h3 {
	margin: 0;
	font-size: 30px;
	font-family: "Indigo-outline";
}

.actionpanel:hover {
	border: 12px solid #881170;
}

.contact {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.contact__list {
	margin: 0;
	text-align: center;
	padding: 32px;
	color: white;
}

.contact__list__item {
	padding: 0;
	display: inline-block;
	list-style-type: none;
	font-size: 16px;
}

.contact__link {
	color: white;
	text-decoration: none;
}

.contact__list__item.variant {
	color: #f6a04a;
	font-weight: bold;
}

.footer {
	color: white;
	text-align: center;
	font-size: 14px;
	background-color: black;
	padding: 32px;
}

.main {
	color: #fff;
	background-color: #560094;
	text-align: center;
	padding: 32px;
}

.projects {
	background-color: rgb(192, 34, 72);
}

.copyright {
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo-footer {
	height: 14px;
	margin: 0 8px;
}

h1 {
	font-family: "Indigo-Outline";
	letter-spacing: 3px;
	font-size: 40px;
}

h3,
h2 {
	font-family: "Indigo-Regular";
	letter-spacing: 3px;
	font-size: 20px;
	color: #fff;
}

.projects {
	margin: 0;
	padding: 32px;
	background-color: #fff;
}

.carousel {
	display: flex;
	margin-top: 32px;
}

.item {
	cursor: pointer;
	width: 25%;
	margin: 16px;
	color: white;
	background-color: #560094;
	background-color: white;
	text-align: center;
	color: black;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
	margin-bottom: 32px;
	border-radius: 10px;
	overflow: hidden;
}

.item:hover {
	background-color: #fff;
	color: black;
	transition: background-color 0.3s ease-in-out;
}

img {
	width: 100%;
}

.info {
	padding: 0 16px 16px;
}

h2 {
	font-family: "Indigo-Outline";
	color: black;
	text-align: center;
	font-size: 30px;
	margin: 0;
}

h3.variant {
	font-family: "Indigo-Outline";
	color: #f6a04a;
}

@media all and (max-width: 768px) {
	.mobile {
		display: block !important;
	}
	.flex {
		display: block !important;
	}
	.item {
		width: 100%;
		display: block;
		margin: 16px 0;
		background-color: white;
		text-align: center;
		color: black;
		box-shadow: 0 2px 7px rgba(0, 0, 0, 0.28);
		margin-bottom: 32px;
	}
}
